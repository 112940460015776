@font-face {
  font-family: 'CormorantGaramond';
  src: url('/public/fonts/CormorantGaramond-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'EmithaScript';
  src: url('/public//fonts/Emitha-Script.ttf') format('truetype'),
       url('/public/fonts/Emitha-Script.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  background-image: url('/public/images/landing.jpg');
  background-size: cover;
  background-position: center;
  font-family: 'CormorantGaramond', serif;
}

.emitha-script {
  font-family: 'EmithaScript';
}

.overlay {
  background: rgba(255, 255, 255, 0.8);
}

.fade {
  opacity: 0;
  transition: opacity 0.5s;
}

.fade-in {
  opacity: 1;
}

.form-checkbox:checked {
  background-color: #4a5568;
  border: none;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}
